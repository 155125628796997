<template>
  <nav class="hibi-nav">
    <div class="hibi-nav__main-drop-menu">
      <div @click="showDropdownMenu"> 
        <svg-button>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 8H25" stroke="#F8F8F8" stroke-width="4"/>
            <path d="M3 14H25" stroke="#F8F8F8" stroke-width="4"/>
            <path d="M3 20H25" stroke="#F8F8F8" stroke-width="4"/>
          </svg>
        </svg-button>
      </div>

      <div>
        <span v-if="!login" @click="logIn()"> 
          <svg-button>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="13.5" cy="8.5" r="3.5" stroke="#F8F8F8" stroke-width="2"/>
              <mask id="path-2-inside-1_1_4" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9871 24H4.01293C4.27294 18.9857 8.42101 15 13.5 15C18.579 15 22.7271 18.9857 22.9871 24Z"/>
              </mask>
              <path d="M4.01293 24L2.01562 23.8964L1.90654 26H4.01293V24ZM22.9871 24V26H25.0935L24.9844 23.8964L22.9871 24ZM4.01293 26H22.9871V22H4.01293V26ZM6.01025 24.1036C6.21543 20.1466 9.49089 17 13.5 17V13C7.35113 13 2.33045 17.8248 2.01562 23.8964L6.01025 24.1036ZM13.5 17C17.5091 17 20.7846 20.1466 20.9898 24.1036L24.9844 23.8964C24.6696 17.8248 19.6489 13 13.5 13V17Z" fill="#F8F8F8" mask="url(#path-2-inside-1_1_4)"/>
            </svg>
          </svg-button>
        </span>

        <span v-else @click="logOut()"> 
          <svg-button>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="13.5" cy="8.5" r="4.5" fill="#F8F8F8"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9871 24H4.01293C4.27294 18.9857 8.42101 15 13.5 15C18.579 15 22.7271 18.9857 22.9871 24Z" fill="#F8F8F8"/>
            </svg>
          </svg-button>
        </span>

      </div>

    </div>

    <div class="hibi-nav__main-menu">
      <div>
        <span class="hibi-nav__item">
          <AppLink to="/calendar">Events</AppLink>
        </span>
<!--
        <span class="hibi-nav__item">
          <AppLink to="/channel" >News</AppLink>
        </span>
-->
        <span class="hibi-nav__item">
          <AppLink to="/news/all" >News</AppLink>
        </span>
<!--
        <span class="hibi-nav__item">
          <AppLink to="/knowledge">Knowledge</AppLink>
        </span>
-->
        <span class="hibi-nav__item">
          <AppLink to="/knowledge/all">Knowledge</AppLink>
        </span>

        <span v-if="login" class="hibi-nav__item">
          <AppLink to="/gantt" >Gantt</AppLink>
        </span>

        <span class="hibi-nav__item">
          <AppLink to="/about" >About</AppLink>
        </span>

      </div>

<!--
      <div v-if="i_master" class="hibi-nav__item">
        <AppLink to="/Master" >Master</AppLink>
      </div>
-->

      <div class="hibi-rtl">
        <span v-if="!login" class="hibi-nav__item">
          <AppLink to="/login">Log&nbsp;In</AppLink>
        </span>
        <span  v-else class="hibi-nav__item">
          <button @click="logOut()" class="hibi-button">Log&nbsp;Out </button>
        </span>
        
        <span v-if="login" class="hibi-nav__item">
          <AppLink to="/signup">Sign&nbsp;Up</AppLink>
        </span>

      </div>

    </div>
    
  </nav>

  <div class="hibi-nav__main-drop-menu-content" v-if="isDropdown">
    <AppLink to="/calendar" @click="hideDropdown()">Events</AppLink>
<!--
    <AppLink to="/channel" >News</AppLink>
-->
    <AppLink to="/news/all" >News</AppLink>
    <AppLink to="/knowledge/all">Knowledge</AppLink>
    <AppLink to="/about" >About</AppLink>
  </div>
  

  <router-view/>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-text">
        © 2021-2023 Mikalai Homza
      </div>
      <div class="footer-text">
        aivironcom@gmail.com
        <button @click="copyEmail">Copy</button>
      </div>
    </div>
  </footer>
</template>

<script>
import AppLink from './components/router/applink.vue'
import { useHead } from '@vueuse/head'

export default {
  name: 'App',
  /*
  <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="google-site-verification" content="HwY70k2fIs6tdFjpQS8mmySYcKHdf9358lkojMEuKNY" />
    <title>Aiviron HI-BI</title>
    <meta name="viewport" content="width=device-width,initial-scale=1.0">
    <meta name="description" content="HI-BI One system for both hobbies and business: Рµvents, news, knowledge, projects, image, music, document, library, private. Hobby Intelligence & Business Intelligence"/>
    <link rel='canonical' href='https://www.aiviron.com' />
    <link rel="icon" href="<%= BASE_URL %>favicon.ico">

    <meta property="og:locale" content="en-us" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="HI-BI One system for both hobbies and business. Hobby Intelligence & Business Intelligence" />
    <meta property="og:description" content="HI-BI is a system  which serves as a single point of work with information and doing business:
      Рµvents, calendar, news, knowledge, projects, document, image, music, library, private ..." />
    <meta property="og:url" content="https://www.aiviron.com" />
    <meta property="og:image" content="<%= BASE_URL %>favicon.ico" />
    <meta property="og:site_name" content="Aiviron" />
*/
  data() {
    return {
      isDropdown: false,
    }
  },
  watch: {
    $route () {
      //console.log(to, from)
      this.isDropdown = false
    }
  },

  components: {
    AppLink
  },

  beforeCreate () {

    useHead(
      {
        title: 'Aiviron HI-BI',
        link: [
          {
            rel: 'canonical',
            href: 'https://www.aiviron.com'
          },
          {
            rel: 'icon',
            href: '/favicon.ico'
          }
        ],
        meta: [
          { name: 'description', content: 'HI-BI One system for both hobbies and business: Events, news, knowledge, projects, image, music, document, library, private. Hobby Intelligence & Business Intelligence'},

          { property: 'og:locale', content: 'en-us'},
          { property: 'og:type', content: 'article'},
          { property: 'og:title', content: 'HI-BI One system for both hobbies and business. Hobby Intelligence & Business Intelligence'},
          { property: 'og:description', content: 'HI-BI is a system  which serves as a single point of work with information and doing business: Events, calendar, news, knowledge, projects, document, image, music, library, private ...'},
          { property: 'og:url', content: 'https://www.aiviron.com'},
          { property: 'og:image', content: '/favicon.ico'},
          { property: 'og:site_name', content: 'Aiviron'},
        ],

      }
    )

  },
  computed: {
    login () {
      //console.log('App interfaces: ', this.$store.getters.interfaces)
      return this.$store.getters.interfaces != 0
    },
    i_master () {
      return (this.$store.getters.interfaces & 2) != 0
    },
    i_uphibi () {
      return (this.$store.getters.interfaces & 8) != 0
    },
    i_upevent () {
      return (this.$store.getters.interfaces & 32) != 0
    }
  },
  methods: {
    showDropdownMenu () {
      this.isDropdown = !this.isDropdown
    },
    logIn () {
      this.$router.push('/login')
    },
    async logOut () {
      //console.log('auth_logout')

      await this.$store.dispatch('auth_logout')
        .then(() => {
          //console.log('auth_logout')
          this.$router.push('/')
        })
      .catch((error) => {
        console.log(error)
      })

      await this.$store.dispatch('set_interfaces')
        .then(() => {
          this.$router.push('/calendar')
        })
      .catch((error) => {
        console.log(error)
      })

    },
    copyEmail () {
      navigator.clipboard.writeText("aivironcom@gmail.com")
        //.then(() => console.log("Done!"))
        //.catch(err => console.error(err))
    }
  }

}
</script>

<style lang="scss">
  @import '@/assets/app.scss';
</style>
